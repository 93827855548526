import { lerp } from '@/assets/ts/utils'

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createLinearGradient(0, canvas.height * 0, 0, canvas.height * 1)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  const scale = 0.9

  for (let i = 0; i < 10; i++) {
    ctx.beginPath()
    ctx.ellipse(
      canvas.width / 2,
      canvas.height / 2,
      lerp(scale * canvas.height / 10, scale * canvas.height / 2, (i + 1) / 10),
      lerp(scale * canvas.height / 2.4, scale * canvas.height / 2, i / 10),
      Math.sin(time * Math.PI) / 3,
      0,
      Math.PI * 2
    )
    ctx.stroke()
  }
}

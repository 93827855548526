
















import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({ })
export default class TempoChoice extends Vue {
  index = 0

  mounted (): void {
    this.$store.dispatch('chooseTempo', 0)
  }

  @Watch('index')
  onIndexChange (val: number): void {
    this.$store.dispatch('chooseTempo', val)
  }
}

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createLinearGradient(canvas.width * 0.25, 0, canvas.width * 0.75, 0)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  for (let i = 0; i <= 14; i++) {
    ctx.beginPath()
    ctx.arc(canvas.width / 2, ((4 + Math.cos(time + (i / 10) * Math.PI / 2)) / 4 * size(8) * (i - 7)) + canvas.height / 2, canvas.height / 4, 0, 2 * Math.PI)
    ctx.stroke()
  }
}



















import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import TracksSlider from '@/components/TracksSlider.vue'
import Icon from '@/components/Icon.vue'
// import raf, { RAFSubscription } from '@/assets/ts/raf'

@Component({ components: { TracksSlider, Icon } })
export default class Main extends Vue {
  // progress = 0
  // sub!: RAFSubscription

  // mounted (): void {
  //   this.sub = raf.subscribe(() => {
  //     const duration = store.getters.getDuration
  //     const time = store.state.audioContext.currentTime - store.state.timeSkipped
  //     this.progress = (time % duration) / duration
  //   })
  // }

  play (): void {
    store.dispatch('play')
  }

  pause (): void {
    store.dispatch('pause')
  }

  stop (): void {
    store.dispatch('stop')
    const inner = this.$el.querySelector('.line .inner') as HTMLElement
    inner.style.animationName = 'none'
    inner.style.width = '0'
    setTimeout(() => { inner.style.animationName = '' }, 10)
  }
}

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gradient = (ctx as any).createConicGradient ? (ctx as any).createConicGradient(Math.PI / 2, canvas.width / 2 + size(12 * 3), canvas.height / 2 + size(12 * 3)) : ctx.createLinearGradient(0, 0, canvas.width, 0)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.25, colors[1])
  gradient.addColorStop(0.5, colors[2])
  gradient.addColorStop(0.75, colors[1])
  gradient.addColorStop(1, colors[0])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  for (let i = 0; i < 12; i++) {
    blob(
      canvas.width / 2 + size((12 - i) * (Math.cos(time * Math.PI / 2) + 2)),
      canvas.height / 2 + size((12 - i) * (Math.cos(time * Math.PI / 2) + 2)),
      canvas.height / 2 * (i + 0.5) / 13)
    ctx.stroke()
  }

  function blob (x0: number, x1: number, radius: number): void {
    const prec = 4
    ctx.beginPath()
    ctx.moveTo(x0 + Math.cos(0) * radius, x1 + Math.sin(0) * radius)
    for (let i = 1; i <= prec; i++) {
      const v = (n: number) => Math.sin((n % prec) * (12228 + 1.4 * Math.cos(time * Math.PI / 4)))
      ctx.bezierCurveTo(
        x0 + Math.cos(Math.PI * 2 * (i - 1) / prec) * radius + Math.cos(v(i - 1) + Math.PI / 2 + Math.PI * 2 * (i - 1) / prec) * 2 * radius / prec,
        x1 + Math.sin(Math.PI * 2 * (i - 1) / prec) * radius + Math.sin(v(i - 1) + Math.PI / 2 + Math.PI * 2 * (i - 1) / prec) * 2 * radius / prec,
        x0 + Math.cos(Math.PI * 2 * i / prec) * radius + Math.cos(v(i) - Math.PI / 2 + Math.PI * 2 * i / prec) * 2 * radius / prec,
        x1 + Math.sin(Math.PI * 2 * i / prec) * radius + Math.sin(v(i) - Math.PI / 2 + Math.PI * 2 * i / prec) * 2 * radius / prec,
        x0 + Math.cos(Math.PI * 2 * i / prec) * radius,
        x1 + Math.sin(Math.PI * 2 * i / prec) * radius
      )
    }
    ctx.closePath()
  }
}


export default [
  {
    name: 'Tragique',
    colors: {
      gradient: ['#0000FF', '#FF0000', '#7600FF'],
      background: '#FF0000'
    },
    tempos: [
      {
        value: '100',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./tragic/100/drums/Loops Drums.wav'), effects: [] },
            { name: 'Perc', track: 'drums', file: require('./tragic/100/drums/Loops Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./tragic/100/bass/Loops Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Pad', track: 'pad', file: require('./tragic/100/melody/Loops Pad.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./tragic/100/melody/Loops Vocals.wav'), effects: [] }
          ]
        }
      },
      {
        value: '110',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./tragic/110/drums/Loops 110 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./tragic/110/drums/Loops 110 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./tragic/110/bass/Loops 110 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./tragic/110/melody/Loops 110 Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./tragic/110/melody/Loops 110 Pad.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./tragic/110/melody/Loops 110 Vocals.wav'), effects: [] }
          ]
        }
      },
      {
        value: '90',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./tragic/90/drums/Loops 90 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./tragic/90/drums/Loops 90 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./tragic/90/bass/Loops 90 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./tragic/90/melody/Loops 90 Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./tragic/90/melody/Loops 90 Pad.wav'), effects: [] }
          ]
        }
      }
    ]
  },
  {
    name: 'Triste',
    colors: {
      gradient: ['#006837', '#00FFFF', '#0000FF'],
      background: '#00FFFF'
    },
    tempos: [
      {
        value: '100',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./sad/100/drums/Loops Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./sad/100/drums/Loops Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./sad/100/bass/Loops Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./sad/100/melody/Loops Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./sad/100/melody/Loops Pad.wav'), effects: [] }
          ]
        }
      },
      {
        value: '110',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./sad/110/drums/Loops 110 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./sad/110/drums/Loops 110 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./sad/110/bass/Loops 110 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./sad/110/melody/Loops 110 Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./sad/110/melody/Loops 110 Pad.wav'), effects: [] }
          ]
        }
      },
      {
        value: '90',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./sad/90/drums/Loops 90 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./sad/90/drums/Loops 90 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./sad/90/bass/Loops 90 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Brass', track: 'brass', file: require('./sad/90/melody/Loops 90 Brass.wav'), effects: [] },
            { name: 'Keys', track: 'keys', file: require('./sad/90/melody/Loops 90 Keys.wav'), effects: [] },
            { name: 'Strings', track: 'strings', file: require('./sad/90/melody/Loops 90 Strings.wav'), effects: [] }
          ]
        }
      }
    ]
  },
  {
    name: 'Mélancolique',
    colors: {
      gradient: ['#FF00FF', '#00FFFF', '#7600FF'],
      background: '#7600FF'
    },
    tempos: [
      {
        value: '100',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./melancolic/100/drums/Loops Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./melancolic/100/drums/Loops Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./melancolic/100/bass/Loops Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./melancolic/100/melody/Loops Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./melancolic/100/melody/Loops Pad.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./melancolic/100/melody/Loops Vocals.wav'), effects: [] }
          ]
        }
      },
      {
        value: '110',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./melancolic/110/drums/Loops 110 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./melancolic/110/drums/Loops 110 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./melancolic/110/bass/Loops 110 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Brass', track: 'brass', file: require('./melancolic/110/melody/Loops 110 Brass.wav'), effects: [] },
            { name: 'Strings 2', track: 'strings', file: require('./melancolic/110/melody/Loops 110 Strings 2.wav'), effects: [] },
            { name: 'Strings 3', track: 'strings', file: require('./melancolic/110/melody/Loops 110 Strings 3.wav'), effects: [] },
            { name: 'Strings', track: 'strings', file: require('./melancolic/110/melody/Loops 110 Strings.wav'), effects: [] }
          ]
        }
      },
      {
        value: '90',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./melancolic/90/drums/Loops 90 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./melancolic/90/drums/Loops 90 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./melancolic/90/bass/Loops 90 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./melancolic/90/melody/Loops 90 Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./melancolic/90/melody/Loops 90 Pad.wav'), effects: [] }
          ]
        }
      }
    ]
  },
  {
    name: 'Chill',
    colors: {
      gradient: ['#7600FF', '#FFFF00', '#0000FF'],
      background: '#0000FF'
    },
    tempos: [
      {
        value: '100',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./chill/100/drums/Loops Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./chill/100/drums/Loops Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./chill/100/bass/Loops Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./chill/100/melody/Loops Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./chill/100/melody/Loops Pad.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./chill/100/melody/Loops Vocals.wav'), effects: [] }
          ]
        }
      },
      {
        value: '110',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./chill/110/drums/Loops 110 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./chill/110/drums/Loops 110 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./chill/110/bass/Loops 110 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./chill/110/melody/Loops 110 Keys.wav'), effects: [] },
            { name: 'Pad', track: 'pad', file: require('./chill/110/melody/Loops 110 Pad.wav'), effects: [] }
          ]
        }
      },
      {
        value: '90',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./chill/90/drums/Loops 90 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./chill/90/drums/Loops 90 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./chill/90/bass/Loops 90 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Strings', track: 'strings', file: require('./chill/90/melody/Loops 90 Strings.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./chill/90/melody/Loops 90 Vocals.wav'), effects: [] }
          ]
        }
      }
    ]
  },
  {
    name: 'Joyeux',
    colors: {
      gradient: ['#FF0000', '#FFFF00', '#FF00FF'],
      background: '#FF00FF'
    },
    tempos: [
      {
        value: '100',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./happy/100/drums/Loops Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./happy/100/drums/Loops Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./happy/100/bass/Loops Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./happy/100/melody/Loops Keys.wav'), effects: [] },
            { name: 'Strings', track: 'strings', file: require('./happy/100/melody/Loops Strings.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./happy/100/melody/Loops Vocals.wav'), effects: [] },
            { name: 'Woodwinds', track: 'woodwinds', file: require('./happy/100/melody/Loops Woodwinds.wav'), effects: [] }
          ]
        }
      },
      {
        value: '110',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./happy/110/drums/Loops 110 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./happy/110/drums/Loops 110 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./happy/110/bass/Loops 110 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Keys', track: 'keys', file: require('./happy/110/melody/Loops 110 Keys.wav'), effects: [] },
            { name: 'Strings 2', track: 'strings', file: require('./happy/110/melody/Loops 110 Strings 2.wav'), effects: [] },
            { name: 'Strings 3', track: 'strings', file: require('./happy/110/melody/Loops 110 Strings 3.wav'), effects: [] },
            { name: 'Strings', track: 'strings', file: require('./happy/110/melody/Loops 110 Strings.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./happy/110/melody/Loops 110 Vocals.wav'), effects: [] }
          ]
        }
      },
      {
        value: '90',
        loops: {
          drums: [
            { name: 'Drums', track: 'drums', file: require('./happy/90/drums/Loops 90 Drums.wav'), effects: [] },
            { name: 'Perc', track: 'percs', file: require('./happy/90/drums/Loops 90 Perc.wav'), effects: [] }
          ],
          bass: [
            { name: 'Bass', track: 'bass', file: require('./happy/90/bass/Loops 90 Bass.wav'), effects: [] }
          ],
          melody: [
            { name: 'Strings', track: 'strings', file: require('./happy/90/melody/Loops 90 Strings.wav'), effects: [] },
            { name: 'Vocals', track: 'vocals', file: require('./happy/90/melody/Loops 90 Vocals.wav'), effects: [] }
          ]
        }
      }
    ]
  }
]

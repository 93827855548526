export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number, analyser: AnalyserNode): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gradient = (ctx as any).createConicGradient ? (ctx as any).createConicGradient(Math.PI / 2, canvas.width / 2, canvas.height / 2) : ctx.createLinearGradient(0, 0, canvas.width, 0)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.25, colors[1])
  gradient.addColorStop(0.5, colors[2])
  gradient.addColorStop(0.75, colors[1])
  gradient.addColorStop(1, colors[0])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  let vol = 1
  if (animated) {
    const dataArray = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(dataArray)
    const vols = dataArray.filter(i => i > 30)
    vol = vols.reduce((acc, val) => acc + val, 0) / vols.length / 2 / 70
  }

  const n = animated ? Math.floor(10 + 2 * Math.cos(time * Math.PI)) : 10

  for (let i = 0; i < n; i++) {
    ctx.beginPath()
    ctx.arc(canvas.width / 2, canvas.height / 2, canvas.height / 2 * (i + 0.5) * (0.5 + vol / 2) / n, 0, 2 * Math.PI)
    ctx.stroke()
  }
}

import { lerp } from '@/assets/ts/utils'

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number, analyser: AnalyserNode): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  let vol = 1
  if (animated) {
    const dataArray = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(dataArray)
    const vols = dataArray.filter(i => i > 30)
    vol = vols.reduce((acc, val) => acc + val, 0) / vols.length / 2 / 70
  }

  const scale = 0.9 * (0.7 + vol * 0.3)

  const n = Math.floor(7 + 3 * Math.cos(time * Math.PI * 2))

  for (let i = 0; i < n; i++) {
    ctx.beginPath()
    ctx.ellipse(
      canvas.width / 2,
      canvas.height / 2,
      lerp(0, scale * canvas.height / 2, i / (n - 1)),
      scale * canvas.height / 2,
      0,
      0,
      Math.PI * 2
    )
    ctx.stroke()
  }
}

import { lerp } from '@/assets/ts/utils'

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  for (let i = 0; i < 10; i++) {
    ctx.beginPath()
    ctx.ellipse(
      canvas.width / 2 + (i - 3) * size(3),
      canvas.height / 2 + (i - 3) * size(4),
      lerp(canvas.height / 2, canvas.height / (3 + 0.5 * Math.cos(time * Math.PI / 2)), i / 10),
      canvas.height / 6,
      lerp(-Math.PI / (4 + 0.5 * Math.sin(time * Math.PI / 2)), Math.PI / 6, i / 10),
      0,
      Math.PI * 2
    )
    ctx.stroke()
  }
}

export function lerp (start: number, end: number, amt: number): number {
  return (1 - amt) * start + amt * end
}

export function range (current: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
  return ((current - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

const mousePos = { x: 0, y: 0 }

window.addEventListener('dragover', e => {
  mousePos.x = e.clientX
  mousePos.y = e.clientY
})

export function mouse (): { x: number, y: number } {
  return mousePos
}

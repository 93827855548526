



















import { Component, Vue } from 'vue-property-decorator'
import TrackCanvas from '@/components/TrackCanvas/TrackCanvas.vue'
import store from '@/store'
import { mouse } from '@/assets/ts/utils'

type InstrumentName = 'drums' | 'percs' | 'bass' | 'keys' | 'vocals' | 'fx' | 'strings' | 'woodwinds' | 'brass' | 'pad'

@Component({ components: { TrackCanvas } })
export default class Visualizer extends Vue {
  muted = Object.fromEntries(Object.entries(store.state.tracks).map(([name, { muteGain }]) => [name, muteGain.gain.value === 0]))
  currentVolumeChangingTrack?: InstrumentName
  currentVolumeChangingStart!: number
  trackVolumes = Object.fromEntries(Object.entries(store.state.tracks).map(([name, { gain }]) => [name, gain.gain.value]))
  canChange = false

  startVolumeChange (track: InstrumentName, e: DragEvent): void {
    if (e.dataTransfer) {
      const drgmg = this.$el.querySelector('.drgmg') as Element
      e.dataTransfer.setDragImage(drgmg, 0, 0)
    }

    if (this.muted[track]) return
    setTimeout(() => {
      this.currentVolumeChangingTrack = track
      this.currentVolumeChangingStart = mouse().y
      this.canChange = true
    }, 100)
  }

  onVolumeChange (track: InstrumentName): void {
    if (this.currentVolumeChangingTrack && !this.muted[track] && this.canChange) {
      const volumeDiff = Math.round(this.currentVolumeChangingStart - mouse().y) / 5000
      const newVolume = Math.max(0, Math.min(1, store.state.tracks[this.currentVolumeChangingTrack].gain.gain.value + volumeDiff))
      store.state.tracks[this.currentVolumeChangingTrack].gain.gain.value = newVolume
      this.trackVolumes[this.currentVolumeChangingTrack] = newVolume
    }
  }

  endVolumeChange (): void {
    this.currentVolumeChangingTrack = undefined
    this.canChange = false
  }

  toggleTrack (track: InstrumentName): void {
    if (this.muted[track]) {
      store.state.tracks[track].muteGain.gain.value = 1
      this.muted[track] = false
    } else {
      store.state.tracks[track].muteGain.gain.value = 0
      this.muted[track] = true
    }
  }
}






import store from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import sketches from './sketches'
import raf, { RAFSubscription } from '@/assets/ts/raf'

type InstrumentName = 'drums' | 'percs' | 'bass' | 'keys' | 'vocals' | 'fx' | 'strings' | 'woodwinds' | 'brass' | 'pad'

@Component({ })
export default class TrackCanvas extends Vue {
  @Prop({ type: String, default: 'keys' }) track!: InstrumentName
  @Prop({ type: Boolean, default: true }) animated!: boolean
  canvas!: HTMLCanvasElement
  ctx!: CanvasRenderingContext2D
  startTime = Date.now()
  colors!: string[]
  sub!: RAFSubscription

  mounted (): void {
    this.canvas = this.$el as HTMLCanvasElement
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D

    this.canvas.width = Math.floor(this.$el.getBoundingClientRect().width * 2)
    this.canvas.height = Math.floor(this.$el.getBoundingClientRect().height * 2)

    this.colors = this.getColors()

    sketches[this.track].default(this.canvas, this.ctx, this.colors, this.animated, 0, store.state.tracks[this.track].analyser)

    if (this.animated) {
      this.sub = raf.subscribe(() => {
        if (typeof store.state.choices.mood === 'number' && typeof store.state.choices.tempo === 'number') {
          const tempo = store.state.moods[store.state.choices.mood].tempos[store.state.choices.tempo].value
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          const currentBeats = (tempo * (store.state.audioContext.currentTime - store.state.timeSkipped) / 60) % 8
          sketches[this.track].default(this.canvas, this.ctx, this.colors, this.animated, currentBeats, store.state.tracks[this.track].analyser)
        }
      })
    }
  }

  size (n: number): number {
    return this.canvas.width / 300 * n
  }

  getColors (): string[] {
    if (typeof store.state.choices.mood === 'number') {
      return store.state.moods[store.state.choices.mood].colors.gradient
    } else {
      return []
    }
  }

  beforeDestroy (): void {
    if (this.sub) {
      raf.unSubscibe(this.sub)
    }
  }
}

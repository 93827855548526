












































import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import TracksSlider from '@/components/TracksSlider.vue'
import Controls from '@/components/Controls.vue'
import Visualizer from '@/components/Visualizer.vue'
import Icon from '@/components/Icon.vue'

@Component({ components: { TracksSlider, Controls, Visualizer, Icon } })
export default class Main extends Vue {
  loopSelectionOpen = false
  group = ''

  open (group: string): void {
    this.group = group
    this.loopSelectionOpen = true
  }

  back (): void {
    store.dispatch('resetChoices').then(() => {
      store.commit('setView', 'home')
    })
  }
}


export default {
  drums: require('./drums/drums'),
  percs: require('./drums/percs'),
  fx: require('./drums/fx'),
  bass: require('./bass/bass'),
  brass: require('./melody/brass'),
  vocals: require('./melody/vocals'),
  strings: require('./melody/strings'),
  keys: require('./melody/keys'),
  woodwinds: require('./melody/woodwinds'),
  pad: require('./melody/pad')
}

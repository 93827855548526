import Vue from 'vue'
import Vuex from 'vuex'
import loopsData from '@/assets/loops'

Vue.use(Vuex)

type InstrumentName = 'drums' | 'percs' | 'bass' | 'keys' | 'vocals' | 'fx' | 'strings' | 'woodwinds' | 'brass' | 'pad'
type ViewName = 'home' | 'mood' | 'tempo' | 'main'

type LoopsCollection = Array<{
  name: string
  colors: {
    gradient: string[]
    background: string
  }
  tempos: Array<{
    value: number
    loops: {
      drums: Array<{ id: number, name: string, track: string, audio: AudioBuffer, effects: AudioBuffer[] }>
      bass: Array<{ id: number, name: string, track: string, audio: AudioBuffer, effects: AudioBuffer[] }>
      melody: Array<{ id: number, name: string, track: string, audio: AudioBuffer, effects: AudioBuffer[] }>
    }
  }>
}>

interface Choices {
  mood?: number,
  tempo?: number,
  loops: {
    drums?: number
    percs?: number
    bass?: number
    brass?: number
    fx?: number
    keys?: number
    vocals?: number
    pad?: number
    strings?: number
    woodwinds?: number
  }
}

function getCategory (trackName: InstrumentName): 'drums' | 'bass' | 'melody' {
  return {
    drums: 'drums',
    percs: 'drums',
    bass: 'bass',
    brass: 'melody',
    fx: 'drums',
    keys: 'melody',
    vocals: 'melody',
    pad: 'melody',
    strings: 'melody',
    woodwinds: 'melody'
  }[trackName] as 'drums' | 'bass' | 'melody'
}

const ac = new AudioContext()
const gainNode = ac.createGain()
gainNode.connect(ac.destination)
gainNode.gain.value = 0.25
ac.suspend()

const store = new Vuex.Store({
  state: {
    loading: true,
    loadedSounds: 0,
    soundsNumber: 1,
    isPlaying: false,
    isStopped: true,
    timeSkipped: 0,
    currentView: 'home' as ViewName,
    audioContext: ac,
    moods: [] as LoopsCollection,
    choices: {
      mood: undefined,
      tempo: undefined,
      loops: {
        drums: undefined,
        percs: undefined,
        fx: undefined,
        bass: undefined,
        keys: undefined,
        vocals: undefined,
        pad: undefined,
        strings: undefined,
        woodwinds: undefined,
        brass: undefined
      }
    } as Choices,
    tracks: {
      drums: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      percs: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      fx: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      bass: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      keys: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      vocals: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      pad: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      strings: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      woodwinds: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() },
      brass: { source: ac.createBufferSource(), gain: ac.createGain(), muteGain: ac.createGain(), analyser: ac.createAnalyser() }
    }
  },
  mutations: {
    setLoops (state, loops) {
      state.moods = loops
    },
    setView (state, view: ViewName) {
      state.currentView = view
    }
  },
  actions: {
    async loadLoops ({ commit, state }) {
      state.soundsNumber = loopsData.map(d => d.tempos.map(t => Object.values(t.loops).map(i => i.map(l => ([l.file, ...l.effects]))))).flat(4).length
      const loops = await Promise.all(loopsData.map(async ({ name, colors, tempos }) => ({
        name,
        colors,
        tempos: await Promise.all(tempos.sort((a, b) => parseInt(a.value) - parseInt(b.value)).map(async ({ value, loops }) => ({
          value,
          loops: Object.fromEntries(await Promise.all(Object.entries(loops).map(async ([instrument, instrumentLoops]) => [
            instrument,
            await Promise.all(instrumentLoops.map(async ({ name, track, file, effects }, i) => {
              const audio = await loadSound(file)
              const fx = await Promise.all(effects.map(async effect => {
                const a = await loadSound(effect)
                state.loadedSounds++
                return a
              }))

              state.loadedSounds++

              return {
                id: i,
                name,
                track,
                audio,
                effects: fx
              }
            }))
          ])))
        })))
      })))

      Object.values(state.tracks).forEach(track => {
        track.muteGain.gain.value = 1
        track.analyser.smoothingTimeConstant = 0.8
        track.gain.gain.value = 0.75
        track.analyser.connect(track.muteGain)
        track.muteGain.connect(track.gain)
        track.gain.connect(gainNode)
      })

      commit('setLoops', loops)
      setTimeout(() => { state.loading = false }, 500)
    },

    chooseMood ({ state }, index) {
      state.choices.mood = Math.max(0, Math.min(index, state.moods.length - 1))
    },
    chooseTempo ({ state }, index) {
      if (state.choices.mood === undefined) return
      state.choices.tempo = Math.max(0, Math.min(index, state.moods[state.choices.mood].tempos.length - 1))
    },
    chooseLoop ({ state, dispatch }, { track, index }: { track: InstrumentName, index: number }) {
      if (state.choices.mood === undefined || state.choices.tempo === undefined) return

      const first = Object.values(state.choices.loops).every(v => v === undefined)

      const i = state.choices.loops[track] === index ? undefined : index

      state.choices.loops[track] = typeof i === 'number' ? Math.max(0, Math.min(i, state.moods[state.choices.mood].tempos[state.choices.tempo].loops[getCategory(track)].length - 1)) : undefined

      state.tracks[track].source.disconnect()
      state.tracks[track].source = state.audioContext.createBufferSource()

      const loopIndex = state.choices.loops[track]
      if (loopIndex !== undefined) {
        const audio = state.moods[state.choices.mood].tempos[state.choices.tempo].loops[getCategory(track)][loopIndex].audio
        const duration = 60 * (Math.round(state.moods[state.choices.mood].tempos[state.choices.tempo].value * audio.duration / 60 / 8) * 8) / state.moods[state.choices.mood].tempos[state.choices.tempo].value
        const offset = state.isStopped ? 0 : state.audioContext.currentTime - store.state.timeSkipped
        state.tracks[track].source.connect(state.tracks[track].analyser)
        state.tracks[track].source.buffer = audio
        state.tracks[track].source.loop = true
        state.tracks[track].source.start(0, offset % duration)

        if (first) {
          dispatch('play')
        }
      }
    },
    resetChoices ({ state, dispatch }) {
      return dispatch('stop').then(() => {
        state.choices.mood = undefined
        state.choices.tempo = undefined
        Object.keys(state.choices.loops).forEach((track) => {
          state.choices.loops[track as InstrumentName] = undefined
          state.tracks[track as InstrumentName].source.disconnect()
          state.tracks[track as InstrumentName].source = state.audioContext.createBufferSource()
        })
      })
    },
    play ({ state }): void {
      state.audioContext.resume().then(() => {
        state.isPlaying = true
        state.isStopped = false
      })
    },
    pause ({ state }): void {
      state.audioContext.suspend().then(() => {
        state.isPlaying = false
        state.isStopped = false
        // state.pauseTime = state.audioContext.currentTime
      })
    },
    stop ({ state }): Promise<void> {
      return state.audioContext.suspend().then(() => {
        state.isPlaying = false
        state.isStopped = true
        state.timeSkipped = state.audioContext.currentTime

        Object.keys(state.tracks).forEach((trackName) => {
          const track = trackName as InstrumentName
          const audio = state.tracks[track].source.buffer

          state.tracks[track].source.disconnect()
          state.tracks[track].source = state.audioContext.createBufferSource()
          state.tracks[track].source.connect(state.tracks[track].analyser)
          state.tracks[track].source.buffer = audio
          state.tracks[track].source.loop = true
          state.tracks[track].source.start(0)
        })
      })
    }
  },
  getters: {
    loops (state): {
      drums: Array<{ name: string, track: string, audio: AudioBuffer, effects: AudioBuffer[] }>
      bass: Array<{ name: string, track: string, audio: AudioBuffer, effects: AudioBuffer[] }>
      melody: Array<{ name: string, track: string, audio: AudioBuffer, effects: AudioBuffer[] }>
    } | undefined {
      if (typeof state.choices.mood === 'number' && typeof state.choices.tempo === 'number') {
        return state.moods[state.choices.mood].tempos[state.choices.tempo].loops
      } else {
        return undefined
      }
    },
    getDuration (state): number | undefined {
      if (typeof state.choices.mood === 'number' && typeof state.choices.tempo === 'number') {
        return 60 * 16 / state.moods[state.choices.mood].tempos[state.choices.tempo].value
      }
    },
    getCurrentTime (state): number | undefined {
      return state.isStopped ? 0 : state.audioContext.currentTime
    }
  }
})

function loadSound (src: string): Promise<AudioBuffer> {
  const request = new XMLHttpRequest()
  request.open('GET', src, true)
  request.responseType = 'arraybuffer'

  return new Promise((resolve, reject) => {
    request.onload = () => {
      store.state.audioContext.decodeAudioData(request.response, resolve, reject)
    }

    request.send()
  })
}

export default store

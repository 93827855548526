

























import { Component, Prop, Vue } from 'vue-property-decorator'
import TrackCanvas from '@/components/TrackCanvas/TrackCanvas.vue'
import store from '@/store'

type InstrumentName = 'drums' | 'percs' | 'bass' | 'keys' | 'vocals' | 'fx' | 'strings' | 'woodwinds' | 'brass' | 'pad'

@Component({ components: { TrackCanvas } })
export default class TracksSlider extends Vue {
  @Prop({ type: String }) group!: InstrumentName
  openTrack?: number = -1

  chooseLoop (track: string, i: number): void {
    this.openTrack = -1
    store.dispatch('chooseLoop', { track, index: i })
    this.$emit('selectedLoop')
  }

  get tracks (): { bass: string[], melody: string[], drums: string[]} {
    return {
      bass: [...new Set(store.getters.loops.bass.map(({ track }: { track: string }) => track))] as string[],
      melody: [...new Set(store.getters.loops.melody.map(({ track }: { track: string }) => track))] as string[],
      drums: [...new Set(store.getters.loops.drums.map(({ track }: { track: string }) => track))] as string[]
    }
  }
}

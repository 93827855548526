export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createLinearGradient(0, -canvas.height / 2, 0, canvas.height / 2)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.scale(0.9, 0.9)

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  const n = 38
  const a = size(30 + Math.sin(time * Math.PI / 4) * 5)
  const b = size(10)

  for (let i = 0; i < n; i++) {
    ctx.beginPath()
    ctx.moveTo(a, -canvas.height / 2)
    ctx.lineTo(a, 0)
    ctx.stroke()
    ctx.rotate(Math.PI * 2 / n)
  }

  for (let i = 0; i < n; i++) {
    ctx.beginPath()
    ctx.moveTo(a, 0)
    ctx.lineTo(a, canvas.height / 2 - size(b))
    ctx.stroke()
    ctx.rotate(Math.PI * 2 / n)
  }

  ctx.scale(1 / 0.9, 1 / 0.9)
  ctx.translate(-canvas.width / 2, -canvas.height / 2)
}



















import { Component, Vue } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'

@Component({ components: { Icon } })
export default class Home extends Vue {}

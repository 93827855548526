

















import { Component, Vue } from 'vue-property-decorator'

@Component({ })
export default class MoodChoice extends Vue {
  chooseMood (i: number): void {
    this.$store.dispatch('chooseMood', i)
    this.$emit('next')
  }
}

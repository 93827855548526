



























import { Component, Vue } from 'vue-property-decorator'
import Home from '@/components/Home.vue'
import MoodChoice from '@/components/MoodChoice.vue'
import TempoChoice from '@/components/TempoChoice.vue'
import Main from '@/components/Main.vue'
import TempoCanvas from '@/components/TempoCanvas.vue'

@Component({
  components: { Home, MoodChoice, TempoChoice, Main, TempoCanvas }
})
export default class App extends Vue {
  mounted (): void {
    this.$store.dispatch('loadLoops')
  }
}

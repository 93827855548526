




import store from '@/store'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { lerp } from '@/assets/ts/utils'
import raf, { RAFSubscription } from '@/assets/ts/raf'

@Component({ })
export default class App extends Vue {
  canvas!: HTMLCanvasElement
  ctx!: CanvasRenderingContext2D
  tempo = 120
  n = this.tempo / 2
  running = false
  sub!: RAFSubscription

  mounted (): void {
    this.canvas = this.$el as HTMLCanvasElement
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D

    this.canvas.height = window.innerHeight
    this.canvas.width = window.innerWidth

    this.setGradient('#444444')
    this.running = true
    this.draw()
  }

  setGradient (color: string): void {
    const gradient = this.ctx.createLinearGradient(0, 0, 0, this.canvas.height)
    gradient.addColorStop(0, '#000000')
    gradient.addColorStop(0.5, color)
    gradient.addColorStop(1, '#000')
    this.ctx.fillStyle = gradient
  }

  draw (): void {
    this.sub = raf.subscribe(() => {
      if (Math.round(this.n * 2 * 100) / 100 === this.tempo) {
        raf.unSubscibe(this.sub)
        this.running = false
        this.n = this.tempo / 2
      }

      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

      this.n = lerp(this.n, this.tempo / 2, 0.1)
      for (let i = 1; i < this.n / 2; i++) {
        this.ctx.fillRect(this.canvas.width / 2 - 2, 0, 4, this.canvas.height)
        this.ctx.fillRect(this.canvas.width / 2 + i * this.canvas.width / this.n - 2, 0, 4, this.canvas.height)
        this.ctx.fillRect(this.canvas.width / 2 - i * this.canvas.width / this.n - 2, 0, 4, this.canvas.height)
      }
    })
  }

  @Watch('$store.state.choices.tempo')
  onTempoChange (choice?: number): void {
    if (typeof store.state.choices.mood === 'number' && typeof choice === 'number') {
      this.tempo = store.state.moods[store.state.choices.mood].tempos[choice].value
      this.setGradient(store.state.moods[store.state.choices.mood].colors.background)
    } else {
      this.tempo = 120
      this.setGradient('#444444')
    }
    if (!this.running) { this.draw() }
  }

  @Watch('$store.state.choices.mood')
  onMoodChange (choice?: number): void {
    if (typeof choice === 'number') {
      this.setGradient(store.state.moods[choice].colors.background)
    } else {
      this.setGradient('#444444')
    }
    if (!this.running) { this.draw() }
  }

  beforeDestroy (): void {
    if (this.sub) {
      raf.unSubscibe(this.sub)
    }
  }
}

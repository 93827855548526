export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number, analyser: AnalyserNode): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  let vol = 0.5
  if (animated) {
    const dataArray = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(dataArray)
    const vols = dataArray.filter(i => i > 30)
    vol = vols.reduce((acc, val) => acc + val, 0) / vols.length / 2 / 70
    if (!(vol > 0)) vol = 0.5
  }

  const gradient = ctx.createLinearGradient(-canvas.width / 2, 0, canvas.width / 2, 0)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(Math.round(vol * 100) / 100, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.scale(0.9, 0.9)

  const n = 20
  const a = size(5 + Math.cos(time * Math.PI / 4) * 4)
  const b = size(-30 + Math.sin(time * Math.PI / 2) * 4)

  for (let i = 0; i < n; i++) {
    ctx.beginPath()
    ctx.moveTo(-canvas.width / 2, b)
    ctx.bezierCurveTo(
      -3 * canvas.width / 8, (a + b) / 2,
      -3 * canvas.width / 8, a,
      -canvas.width / 4, a
    )
    ctx.bezierCurveTo(
      -canvas.width / 8, a,
      -canvas.width / 8, b,
      0, b
    )
    ctx.bezierCurveTo(
      canvas.width / 8, b,
      canvas.width / 8, a,
      canvas.width / 4, a
    )
    ctx.bezierCurveTo(
      3 * canvas.width / 8, a,
      3 * canvas.width / 8, (a + b) / 2,
      canvas.width / 2, b
    )
    ctx.stroke()
    ctx.rotate(-Math.PI / n)
  }

  ctx.rotate(-Math.PI)
  ctx.scale(1 / 0.9, 1 / 0.9)
  ctx.translate(-canvas.width / 2, -canvas.height / 2)
}

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createRadialGradient(canvas.width / 2, canvas.height / 2, 0.15 * canvas.width, canvas.width / 2, canvas.height / 2, canvas.width / 2)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  const l = 0.17 + 0.02 * Math.cos(time * Math.PI / 2)

  for (let i = 0; i < 23; i++) {
    ctx.beginPath()
    ctx.arc(
      canvas.width / 2 + Math.sin(Math.PI * 2 * i / 23) * 0.3 * canvas.width,
      canvas.height / 2 + Math.cos(Math.PI * 2 * i / 23) * 0.3 * canvas.height,
      l * canvas.width,
      0, 2 * Math.PI
    )
    ctx.stroke()
  }
}

import { lerp } from '@/assets/ts/utils'

export default function fx (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: string[], animated: boolean, time: number): void {
  function size (n: number): number {
    return canvas.width / 300 * n
  }

  const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0)
  gradient.addColorStop(0, colors[0])
  gradient.addColorStop(0.5, colors[1])
  gradient.addColorStop(1, colors[2])

  ctx.scale(0.9, 0.9)
  ctx.translate(canvas.width * 0.05, canvas.height * 0.05)

  ctx.strokeStyle = gradient
  ctx.lineWidth = Math.round(size(4))

  const n = 9
  const l = 0.3 + 0.07 * Math.sin(time * Math.PI / 2)

  for (let j = 0; j < n * 2; j++) {
    const i = j < n ? j : (j - 1)
    const rotation = j < n ? lerp(0, 0.5, j / n) : lerp(-0.5, 0, (j + 1) / n - 1)
    const rotationsize = j < n ? lerp(l, l * 2, j / n) : lerp(l * 2, l, (j + 1) / n - 1)

    ctx.beginPath()
    ctx.moveTo(canvas.width / 2, 0)

    ctx.bezierCurveTo(
      lerp(canvas.width * l, canvas.width / 2, i / (n - 1)),
      0,

      lerp(0, canvas.width / 2, i / (n - 1)) + Math.sin(Math.PI + rotation) * canvas.width * rotationsize,
      canvas.height / 2 + Math.cos(Math.PI + rotation) * canvas.height * rotationsize,

      lerp(0, canvas.width / 2, i / (n - 1)),
      canvas.height / 2
    )

    ctx.bezierCurveTo(
      lerp(0, canvas.width / 2, i / (n - 1)) + Math.sin(rotation) * canvas.width * rotationsize,
      canvas.height / 2 + Math.cos(rotation) * canvas.height * rotationsize,

      lerp(canvas.width * l, canvas.width / 2, i / (n - 1)),
      canvas.height,
      canvas.width / 2,
      canvas.height
    )
    ctx.stroke()
  }

  ctx.translate(-canvas.width * 0.05, -canvas.height * 0.05)
  ctx.scale(1 / 0.9, 1 / 0.9)
}
